// variables

$primary : #1D1D1B;
$primary_lighted : #8e8e8d;
$yellow:#FF8505;
$blue : #06D9FC;
$secondary : #C81859;
$white:#e8e8e8;

$shadow : 0 13px 35px -12px rgb(35 35 35 / 15%);

$facebook:#3B5998;
$twitter: #56ACEE;
$youtube : #B7242A;
$instagram: #C13584;