@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Zilla+Slab:wght@300;400;500;600;700&display=swap');
@import 'variables';
@import "~bootstrap/scss/bootstrap";
//HTML
html,
body {
    scroll-behavior: smooth;
    overflow: auto;
    padding: 0;
    margin: 0;
    width: 100%;
    font-weight: 400;
    color: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
}

body {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    background: $primary;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;

}

/// @MIXINS \\\
@mixin  circle($size) {
    width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;
    border-radius: $size;

}

@mixin shadow ($color : rgb(183 192 206 / 20%)){
    box-shadow:  0 0 10px 0 $color;
}

@mixin lineclamp($lines: 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


a{
    text-decoration: none;
}

.flex-row
{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
}
.flex-column
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.flex-rsb
{
    @extend .flex-row;
    justify-content: space-between;
}
.flex-center
{
    @extend .flex-row;
    justify-content: center;
    align-items: center;
}

/** title */
.titledFont{
    font-family: 'Zilla Slab', serif;
}

//btn
.btn{
    display: inline-block;
    border-radius: 0px;
    font-size: .7rem;
    white-space: nowrap;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
}

.btn-actionner
{
    padding: 0 30px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    background:$secondary;
    line-height: 42px;
    outline: none;
    border: none;
}
.separator{
    min-height:1px;
    background-color: $blue;
    width: 20%;
    margin-bottom: 25px;
    &.secondary
    {
        background-color: $secondary;
    }
}



  
@keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 175, 239, 0.7);
      box-shadow: 0 0 0 0 rgba(0, 174, 239, .3);
    }
    35% {
        -moz-box-shadow: 0 0 0 20px rgba(0, 174, 239, 0);
        box-shadow: 0 0 0 20px rgba(0, 174, 239, 0);
    }
    50% {
        -moz-box-shadow: 0 0 0 40px rgba(0, 174, 239, 0);
        box-shadow: 0 0 0 40px rgba(0, 174, 239, 0);
    }
    70% {
        -moz-box-shadow: 0 0 0 60px rgba(0, 174, 239, 0);
        box-shadow: 0 0 0 60px rgba(0, 174, 239, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 174, 239, 0);
        box-shadow: 0 0 0 100px rgba(0, 174, 239, 0);
    }
  }


.form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: 1px solid #e8e8eb;
    border-radius: 0;
    margin-bottom: 30px;
    height: 45px;
    padding: 10px 20px;
    font-size: 14px;
    &:focus{
        outline: none;
        box-shadow: none;
        background-color: #fff;
    }
}

textarea
{
    min-height: 120px!important;
}
p{
    margin: 0 0 25px;
}

// animations


