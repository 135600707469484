@import "./configs.scss";

.master--hdr {
  z-index: 999 !important;

  .m-upper--hdr {
    background-color: $white;

    .mu--wrapper {
      height: 44px;
      @extend .container;
      @extend .flex-rsb;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        padding: 15px;
        height: unset;

        .mu--ca,
        .mu--socials {
          width: 100%;
          text-align: center;
        }

        .mu--ca {
          a {
            display: block;
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }

      a,
      span {
        color: #666;
        font-weight: 400;
        font-size: 15px;

        i {
          margin-right: 7px;
          font-size: 15px;
        }
      }

      .mu--ca {
        a {
          margin-right: 15px;
        }
      }
    }
  }

  .main--content {
    background-color: $primary;
    box-shadow: 0 13px 35px -12px rgb(35 35 35 / 15%);

    .m--wrapper {
      @extend .container;
      @extend .flex-rsb;
      position: relative;

      @include media-breakpoint-down(md) {
        padding: 10px;

        img {
          height: 40px;
        }
      }

      .logo-c {
        img {
          height: 60px;
        }
      }

      @include media-breakpoint-down(md) {
        .lg-menus {
          display: none;
        }
      }

      @include media-breakpoint-up(md) {
        .sm-menus {
          display: none;
        }
      }

      .small-menus-toggler {
        width: 1.5em;
        height: 1.5em;
        @extend .flex-center;
        font-size: 30px;
        color: #fff;
        background-color: $secondary;
        border-radius: 5px;
        z-index: 100;
      }

      .small-menus-content {
        display: none;
        z-index: 999;

        &.show {
          display: block;
        }
      }

      .menus--c {
        display: inline-block;
        list-style-type: none;
        z-index: 1000;

        li {
          display: inline-block;

          a:not(.btn-actionner) {
            color: $white;
            padding-inline: 0.5rem;
            text-rendering: optimizeLegibility;
            display: block;
            font-size: 14px;
            margin: 0;
            text-transform: uppercase;
            font-weight: 700;
            transition: 350ms;
            transition: 350ms;
            line-height: 78px;
            border-bottom: 4px solid transparent;

            &:hover {
              color: $secondary;
              border-bottom-color: $secondary;
            }
          }

          &:not(:last-child) {
            margin-right: 20px;
          }
        }

        @include media-breakpoint-down(md) {
          position: absolute;
          left: 20px;
          right: 20px;
          top: 70px;
          background-color: #000;
          padding-block: 10px;

          li {
            display: block;
            width: 100%;

            &:not(:last-child) {
              margin-right: 0px;
            }

            a:not(.btn-actionner) {
              line-height: 42px !important;
            }
          }
        }
      }
    }
  }
}

.hp--hiro {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 200px;
  background: linear-gradient(45deg, #19295a, #2ec3fb);
  overflow: hidden;

  .hiro-svg-bg {
    position: absolute;
    z-index: 0;
    opacity: 0.05;
    top: 0;
    left: 50%;
    transform: translate(calc(-50% - 500px), -20%);
    height: 200%;
  }

  .hp--wrapper {
    height: 60vh;
    max-height: 790px;
    @extend .container;
    @extend .flex-rsb;
    justify-content: flex-end;

    .img-wrapper {
      width: 50%;
      text-align: center;

      img {
        width: 50%;
      }

      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }

    .caption--wrapper {
      width: 50%;

      .cw--title {
        color: #fff !important;
      }

      .cw--subtitle {
        color: #c2d9ed;
      }

      .actionner {
        .btn {
          height: 45px;
          line-height: 45px;
          padding: 0 30px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    background-color: #fdfbec;
    background-position: left 25% bottom 0;
    background-size: cover;

    .hp--wrapper {
      min-height: 60vh;

      .caption--wrapper {
        width: 95%;
      }
    }
  }
}

.cw--title {
  @extend .titledFont;
  font-size: 48px;
  font-weight: 300;
  color: $primary;
  line-height: 47px;
  margin-bottom: 30px;

  span {
    font-weight: 700;
  }
}

.cw--subtitle {
  font-size: 16px;
  font-weight: 300;
  color: #555;
  margin-bottom: 15px;
}

@include media-breakpoint-down(md) {
  .cw--title {
    font-size: 31px;
    line-height: 1.4em;
  }
}

.section--info {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 25px;
  color: $white;
  background-color: $primary;
  display: inline-block;
  padding: 3px 18px;
  text-transform: uppercase;
}

.hp--gls {
  min-height: 50vh;
  background-color: $white;

  .hpg--wrapper {
    position: relative;
    @extend .container;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: unset;
      padding-right: unset;
      padding-left: unset;
    }

    .hpg--content {
      position: absolute;
      top: -10vh;
      background-color: $primary;
      min-height: 40vh;
      left: 0;
      right: 0;
      padding: 30px 30px 30px 20px;
      @extend .flex-row;
      justify-content: space-around;
      align-items: flex-start;

      &.no-absolute {
        position: unset;
        left: unset;
        right: unset;
        top: unset;
      }

      > div {
        width: 23%;
      }

      @include media-breakpoint-down(md) {
        position: unset;
        flex-wrap: wrap;
        width: 100%;

        > div {
          width: 100%;
        }
      }
    }
  }
}

.hpg--item {
  .hpg--value {
    color: $blue;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .c--title {
    @extend .titledFont;
    color: $white;
    font-size: 24px;
    font-weight: 600;
    @include lineclamp(2);
    margin-bottom: 25px;
  }

  .ca--caption {
    margin-bottom: 25px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.m--ftr {
  background-color: $primary;
  background: url("../../public/imgs/ftr-01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding-top: 60px;

  .mf--wrapper {
    padding-bottom: 50px;
    @extend .container;
    @extend .flex-row;
    justify-content: space-around;
    align-items: flex-start;

    > div {
      width: 30%;
      margin-right: 2%;
    }

    .lg--ctr {
      margin-block: 10px;

      img {
        height: 100px;
      }
    }

    .section {
      &.m--links {
        width: 46%;
      }

      .s--title {
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 35px;
        @extend .titledFont;
      }

      ul {
        @extend .list-unstyled;

        li {
          display: inline-block;
          line-height: normal;
          font-size: 15px;
          width: 50%;
          padding-bottom: 25px;

          a {
            color: #d5d5d5;

            i {
              display: inline-block;
              margin-right: 10px;
            }

            &:hover {
              color: $secondary;
            }
          }
        }
      }
    }

    .caption-c {
      p {
        color: #d5d5d5;
        margin-top: 35px;
      }
    }

    .ss--contact {
      .ss--title {
        font-size: 20px;
        margin-bottom: 10px;
        color: #fff;
        @extend .titledFont;
        line-height: 1.2;
      }

      ul {
        list-style-type: none;
        @extend .list-unstyled;

        li {
          padding-right: 45px;

          a {
            color: #d5d5d5;
          }
        }
      }
    }
  }

  .b-bar {
    background-color: $primary;

    .b--wrapper {
      @extend .container;
      @extend .flex-rsb;
      min-height: 80px;
      font-weight: 400;

      .credits {
        span {
          color: $secondary;
        }
      }

      .socials {
        @extend .list-inline;

        li {
          display: inline-block;

          &:not(:last-child) {
            margin-right: 10px;
          }

          a {
            color: #fff;
            @extend .flex-center;
            @include circle(40px);
            font-size: 15px;

            &.fb {
              background-color: $facebook;
            }

            &.tw {
              background-color: $twitter;
            }

            &.ytb {
              background-color: $youtube;
            }

            &.inst {
              background-color: $instagram;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .mf--wrapper {
      flex-wrap: wrap;

      > div,
      .section {
        min-width: 100%;
        width: 100%;
        margin-bottom: 25px;
        margin-right: unset;
      }
    }

    .b-bar {
      .b--wrapper {
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: unset;
        padding-block: 2rem;

        > div {
          width: 100%;
        }
      }
    }
  }
}

.hp--ptrns {
  background-color: #fff;

  .p--wrapper {
    @extend .container;
    min-height: 20vh;
    @extend .flex-center;
    justify-content: space-around;
    .it--displayer {
      a {
        display: block;
        margin-bottom: 25px;

        &:not(:last-child) {
          margin-right: 20px;
        }

        img {
          height: 60px;
          margin-bottom: 1rem;
        }
        .caption {
          text-align: center;
          @extend .text-muted;
          text-decoration: underline;
          &:hover {
            color: $info !important;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      min-height: unset;
      padding-block: 25px;
      flex-wrap: wrap;

      a {
        margin-bottom: 25px;

        img {
          height: 40px;
        }
      }
    }
  }
}

.hp--prjcts {
  background-color: $primary;
  background-image: url("../../public/imgs/projection/proj.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 50%;

  .hpp--wrapper {
    @extend .container;
    @extend .flex-row;
    align-items: flex-start;
    min-height: 90vh;

    .img-container {
      min-width: 50%;
      height: 100%;
      @extend .flex-center;
      min-height: 90vh;
      font-size: 5rem;
      .ic--wrapper {
        position: relative;
      }

      a {
        color: $blue;
        box-shadow: $shadow;
      }
      .video-hint {
        white-space: nowrap;
        position: absolute;
        bottom: -50px;
        left: -10px;
        text-align: center;
        color: #222;
        background-color: #fff;
        font-size: 0.9rem;
        padding: 0.4rem 1rem;
        border-radius: 0.5rem;
      }
    }

    .hpp-cap-container {
      width: 50%;
      padding: 100px 60px;

      .hpp--title {
        font-size: 40px;
        font-weight: 300;
        color: $white;
        position: relative;
        @extend .titledFont;
        line-height: 45px;
        margin-bottom: 1.5rem;

        span {
          font-weight: 700;
        }
      }

      p {
        margin: 0 0 50px;
      }

      ul,
      ol {
        padding-left: 5px;
        li {
          h3 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 25px;
          }

          i {
            font-size: 20px;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    background-image: unset;

    .hpp--wrapper {
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      min-height: unset;

      .img-container {
        min-width: unset;
        width: 100%;
        height: 400px;
        min-height: unset;
        background-image: url("../../public/imgs/projection/proj.jpg");
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 100%;
      }

      .hpp-cap-container {
        width: 100%;
      }
    }
  }
}

.pulse {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 174, 239, 0.7);
  animation: pulse 2s infinite;
}

.hp--abt {
  background-color: #e8e8e8;
  color: $primary;

  .abt--wrapper {
    @extend .container;
    min-height: 80vh;
    padding-block: 2rem;
    @extend .flex-rsb;

    > div {
      width: 48%;
    }

    .images-c {
      @extend .flex-rsb;
      flex-wrap: wrap;

      img {
        width: 100%;
        margin-bottom: 2rem;
        border-radius: 5px;
      }
    }

    .hpa--caption-c {
      p {
        margin: 0 0 25px;
        color: #666666;
      }
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      > div {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }
}

.p--contacts {
  background-color: #ffffff;
  padding-block: 120px;

  @include media-breakpoint-down(md) {
    padding-block: 50px;
  }

  .c--wrapper {
    @extend .container;
    box-shadow: $shadow;
    @extend .flex-row;
    align-items: flex-start;
    background-color: #f7f9fb;

    .cw--title {
      font-size: 36px;
    }

    .section--info {
      font-size: 14px;
    }

    .fom-side {
      width: 65%;
      background-color: #ffffff;
      padding: 80px 65px 40px 65px;

      .fs--hdr {
        @extend .flex-column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .infos--s {
      flex-grow: 1;
      padding: 50px 30px 30px;
      color: #666666;

      .info--item {
        h2 {
          font-size: 24px;
          @extend .titledFont;
          font-weight: 600;
          color: #101010;
        }

        ul {
          @extend .list-unstyled;

          li {
            margin-bottom: 15px;
            font-size: 15px;

            span {
              color: $secondary;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      .fom-side,
      .infos--s {
        width: 100%;
        padding: 50px 30px 30px 30px;
      }
    }
  }
}

.hp--news {
  background-color: $white;
  padding-block: 65px;

  .hpn--wrapper {
    @extend .container;

    .post-displayer {
      margin-bottom: 25px;
      color: inherit;

      img {
        width: 100%;
        height: auto;
        max-height: 250px;
        overflow: hidden;
        margin-bottom: 25px;
      }

      p {
        color: $secondary;
        font-size: 15px;
        margin-bottom: 15px;
      }

      .p--title {
        color: #101010;
        font-size: 24px;
        font-weight: 600;
        @extend .titledFont;
        @include lineclamp(1);
        margin-bottom: 25px;
      }
    }
  }
}

.e-404 {
  @extend .flex-center;
  min-height: 60vh;
  background-color: #efefef;
  color: #222;
  padding: 40px 10px;

  * {
    text-align: center;
  }

  .title {
    font-size: 80px;
    @extend .titledFont;
  }

  .subtitle {
    @extend .titledFont;
  }
}

.my-brt-p {
  .hiro-c {
    background-image: url("../../public/imgs/brt/hiro.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    min-height: 50vh;
    position: relative;

    @include media-breakpoint-down(md) {
      min-height: 30vh;
    }

    .c--overlay {
      @extend .flex-center;
      align-items: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #2da1b32f;
    }

    h1 {
      text-align: center;
      font-size: 5rem;
      font-weight: bold;
      color: #fff;
      @extend .titledFont;
      margin-bottom: 5vh;
    }
  }

  &.brt-train {
    .hiro-c {
      background-image: url("../../public/imgs/brt/train.jpg");
    }
  }
}

.wr--section {
  background-color: #fff;

  .s--wrapper {
    @extend .container;

    .sw--item-container {
      width: 100%;
      @extend .flex-center;
    }

    .sw--item {
      min-height: 50vh;
      padding-block: 40px;
      @extend .flex-rsb;
      max-width: 870px;

      &.reverse {
        @include media-breakpoint-down(md) {
          .sw-ic {
            order: 1 !important;
          }

          .sw--content {
            order: 1 !important;
          }
        }
      }

      .sw--ic {
        width: 45%;

        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 2rem;
        }

        img {
          width: 100%;
          max-width: 400px;
          border-radius: 10px;
        }
      }

      .sw--content {
        width: 50%;

        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 2rem;
        }

        .sw--title {
          color: #222;
          @extend .titledFont;
          font-size: 1.3rem;
          font-weight: bold;
          margin-bottom: 2rem;
        }

        .sw--caption {
          max-width: 90%;
          font-size: 0.9rem;
          color: #555;

          @include media-breakpoint-down(md) {
            max-width: unset;
          }
        }
      }

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }
  }
}

.docs-p {
  background-color: #fff;
  min-height: 60vh;
  padding-top: 5vh;
  color: $primary;
  .docs--wrapper {
    @extend .container;
    .item-displayer {
      border: 1px solid whitesmoke;
      border-radius: 5px;
      margin-bottom: 2rem;
      img {
        margin-bottom: 1rem;
      }
      .i--title {
        @extend .h4;
        @extend .text-muted;
        text-align: center;
      }
    }
  }
}

.m-modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);

  /* The Close Button */
  .close {
    color: #aaa;
    font-size: 50px;
    font-weight: bold;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  .btn {
    position: absolute;
    bottom: 20px;
  }
}

.videoWrapper iframe {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 90%;
  height: 70%;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.hp--sectors {
  background-color: white;
  padding: 8vh 0;

  .s--wrapper {
    @extend .container;
    h1.hp--title {
      font-size: 2rem;
      color: $primary;
      line-height: 4rem;
      border-bottom: 1px solid $primary_lighted;
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.9rem;
      color: #222;
      margin-bottom: 2rem;
    }

    ul {
      @extend .list-unstyled;
      li {
        display: inline-block;
        margin-right: 1rem;
        margin-bottom: 1rem;
        a {
          color: inherit;
          .it-displayer {
            @extend .flex-row;
            align-items: center;
            max-width: 300px;
            height: 90px;
            background-color: #efefef;
            border: 2px solid transparent;
            padding-right: 1rem;
            &.active {
              border-color: $secondary;
            }
            .ic-c {
              width: 100px;
              min-width: 100px;
              @extend .flex-center;
              img {
                width: 80px;
              }
            }
            .caption {
              font-size: 0.9rem;
              .itd--title {
                color: $primary;
                font-size: 0.8rem;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
              }
              p {
                @extend .text-muted;
                @include lineclamp(2);
                font-size: 0.7rem;
                margin-bottom: unset !important;
              }
            }
          }
        }
      }
    }
  }
}

.sctrs--page {
  background-color: white;
  color: $primary;
  padding: 10vh 10px;
  .wrapper {
    @extend .container;
  }
}
.about-pdtk-p {
  background: white;
  color: $primary;
  min-height: 100vh;
  padding: 5vh 0;
  section {
    &:nth-child(even) {
      background-color: #efefef;
    }

    .aps--wrapper {
      @extend .container;
      padding: 5vh 10px;
      h1 {
        color: $secondary;
        @extend .h1;
        margin-bottom: 2rem;
        text-align: center;
      }
      h3 {
        @extend .h2;
        color: $info;
        @extend .mb-3;
      }
      .h--caption {
        text-align: center;
        @extend .text-muted;
      }
      .s--wrapper {
        @extend .flex-rsb;
        padding: 5vh 0;
        .img-c,
        .d--content {
          width: 48%;
        }
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          .img-c,
          .d--content {
            width: 100%;
            margin-bottom: 1rem;
          }
        }
        .img-c {
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
        .d--content {
          p {
            text-align: justify;
          }
        }
      }
      .ab-pdtk--sub-section {
        @extend .flex-row;
        @include media-breakpoint-down(md) {
          > div {
            width: 100% !important;
            flex-wrap: wrap;
            img {
              display: none;
            }
          }
        }
        > div {
          width: 50%;

          img {
            width: 80%;
          }
        }
      }
    }
  }
}
.sc--s {
  h2 {
    @extend .h4;
    margin-bottom: 1.5rem;
    color: $primary;
    text-transform: uppercase;
  }
  p {
    text-align: justify;
    margin-bottom: 1rem;
  }
}
.dashboard-p-c {
  background-color: white;
  .wrapper {
    @extend .container;
  }
}

.dashboard-p-c {
  ul {
    text-align: center;
    li {
      display: inline-block;
      margin: 10px;
    }
  }
  .it-displayer {
    @extend .flex-row;
    align-items: center;
    max-width: 300px;
    background-color: #efefef;
    border: 2px solid transparent;
    padding: 10px 1.5rem;
    cursor: pointer;
    &.active {
      border-color: $secondary;
    }
    .caption {
      font-size: 0.9rem;
      .itd--title {
        color: $primary;
        font-size: 0.8rem;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.card {
  background-color: #fff;
}
